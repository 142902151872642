@import "bootstrap/bootstrap";
@import "bootstrap/todc-bootstrap";
@import "font-awesome/font-awesome";
@import "theme/style";
@import "theme/color_3";

@media (max-width: 767px) {
    #top_header {
        height: inherit;
    }
}

#top_header .user_info {
    margin-top: 11px;

    .caret {
        margin-left: 5px;
        margin-top: -2px;
    }
}
